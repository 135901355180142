import React, { useRef } from 'react';
import { AutocompleteComponent, DialogComponent } from '../../../../Components';
import { useTranslation } from 'react-i18next';
import Button from '@material-ui/core/Button';
import {
  GetContacts,
  GetLookupItemsByLookupTypeName,
} from '../../../../Services';
import { ClassificationsContactEnum } from '../../../../Enums';
import {
  PriceFilter,
  SizeFilter,
} from '../../ResaleUnitsView/ResaleUnitsFilters';
import { ServiceChargeFilter } from './ServiceChargeFilter';
import './MoreFiltersPrimaryUnitsDialog.scss';
import { ROIFilter } from './ROIFilter';
import { DownPaymentFilter } from './DownPaymentFilter';

export const MoreFiltersPrimaryUnitsDialog = ({
  translationPath,
  parentTranslationPath,
  setSelected,
  selected,
  data,
  setData,
  filterCriteriaChangeHandler,
  isOpen,
  isClose,
  RangeSliderFilter,
  detailsUnitsList,
}) => {
  const { t } = useTranslation(parentTranslationPath);
  const searchTimer = useRef(null);

  const getPaymentPlanLookups = async () => {
    const res = await GetLookupItemsByLookupTypeName({
      lookUpName: 'PlanType',
      pageSize: 100,
      pageIndex: 1,
    });
    if (!(res && res.status && res.status !== 200)) {
      setData({ id: 'paymentPlans', value: res.result || [] });
    } else setData({ id: 'paymentPlans', value: [] });
  };
  const getPropertyPlanLookups = async () => {
    const res = await GetLookupItemsByLookupTypeName({
      lookUpName: 'Property Plan',
      pageSize: 100,
      pageIndex: 1,
    });
    if (!(res && res.status && res.status !== 200)) {
      setData({ id: 'propertyPlans', value: res.result || [] });
    } else setData({ id: 'propertyPlans', value: [] });
  };

  const getDeveloperOptions = async (searchValue) => {
    const res = await GetContacts({
      pageIndex: 0,
      pageSize: 10,
      search: searchValue || '',
      classificationId: ClassificationsContactEnum[0].Id,
    });
    if (!(res && res.status && res.status !== 200))
      setData({ id: 'developers', value: res?.result || [] });
    else setData({ id: 'developers', value: [] });
  };
  return (
    <DialogComponent
      titleText='More filters'
      maxWidth='sm'
      isOpen={isOpen}
      onCloseClicked={isClose}
      dialogContent={
        <div className='more-filters-dialog-container'>
          <div className='filters-container'>
            <AutocompleteComponent
              idRef='developerRef'
              inputPlaceholder={t(`${translationPath}developer`)}
              labelValue={t(`${translationPath}developer`)}
              selectedValues={selected.developer || null}
              wrapperClasses='w-min-unset m-2 mr-1'
              data={data.developers || []}
              displayLabel={(option) =>
                option.contact.company_name ||
                (option.contact &&
                  (option.contact.first_name || option.contact.last_name) &&
                  `${option.contact.first_name} ${option.contact.last_name}`) ||
                ''
              }
              multiple={false}
              withoutSearchButton
              onChange={(_, newValue) => {
                setSelected({
                  id: 'developer',
                  value: {
                    contactsId: newValue?.contactsId,
                    contact: {
                      first_name:
                        newValue?.contact && newValue?.contact.first_name,
                      last_name:
                        newValue?.contact && newValue?.contact.last_name,
                      company_name:
                        newValue?.contact && newValue?.contact.company_name,
                    },
                  },
                });

                const developerKey = 'DeveloperName';
                const developerValue =
                  newValue?.contact?.company_name ||
                  (newValue?.contact &&
                    (newValue?.contact?.first_name ||
                      newValue?.contact?.last_name) &&
                    `${newValue?.contact?.first_name} ${newValue?.contact?.last_name}`) ||
                  '';
                const searchType = 2;

                filterCriteriaChangeHandler(
                  developerKey,
                  developerValue,
                  searchType
                );
              }}
              onOpen={() => {
                if (data.developers && data.developers.length == 0)
                  getDeveloperOptions();
              }}
              onInputKeyUp={(e) => {
                const { value } = e.target;
                if (searchTimer.current) clearTimeout(searchTimer.current);
                searchTimer.current = setTimeout(() => {
                  getDeveloperOptions(value);
                }, 600);
              }}
            />
            <AutocompleteComponent
              idRef='propertyPlanRef'
              inputPlaceholder={t(`${translationPath}property-Plan`)}
              labelValue={t(`${translationPath}property-Plan`)}
              selectedValues={selected.propertyPlan || null}
              wrapperClasses='w-min-unset m-2 mr-1'
              data={data.propertyPlans || []}
              displayLabel={(option) => option.lookupItemName || ''}
              multiple={false}
              withoutSearchButton
              onChange={(_, newValue) => {
                setSelected({ id: 'propertyPlan', value: newValue });
                const propertyPlanValue = newValue?.lookupItemId || null;
                const propertyPlanKey = 'PropertyPlanId';
                const searchType = 1;

                filterCriteriaChangeHandler(
                  propertyPlanKey,
                  propertyPlanValue,
                  searchType
                );
              }}
              onOpen={() => {
                if (data.propertyPlans && data.propertyPlans.length == 0)
                  getPropertyPlanLookups();
              }}
            />

            <AutocompleteComponent
              idRef='paymentPlanRef'
              wrapperClasses='w-min-unset m-2 mr-1'
              inputPlaceholder={t(`${translationPath}payment-plan`)}
              labelValue={t(`${translationPath}payment-plan`)}
              selectedValues={selected.paymentPlan || null}
              data={data.paymentPlans || []}
              displayLabel={(option) => option.lookupItemName || ''}
              multiple={false}
              withoutSearchButton
              onChange={(_, newValue) => {
                setSelected({ id: 'paymentPlan', value: newValue });
                const paymentPlanKey = 'PaymentPlanType';
                const paymentPlanValue = newValue?.lookupItemName || null;
                const searchType = 1;

                filterCriteriaChangeHandler(
                  paymentPlanKey,
                  paymentPlanValue,
                  searchType
                );
              }}
              onOpen={() => {
                if (data.paymentPlans && data.paymentPlans.length == 0)
                  getPaymentPlanLookups();
              }}
            />
            <div className='sliders-container'>
              <PriceFilter
                PriceAndSizeChangeFilters={RangeSliderFilter}
                translationPath={translationPath}
                parentTranslationPath={parentTranslationPath}
                setSelected={setSelected}
                selected={selected}
              />
              <SizeFilter
                PriceAndSizeChangeFilters={RangeSliderFilter}
                translationPath={translationPath}
                parentTranslationPath={parentTranslationPath}
                setSelected={setSelected}
                selected={selected}
              />

              <ROIFilter
                RangeSliderFilter={RangeSliderFilter}
                translationPath={translationPath}
                parentTranslationPath={parentTranslationPath}
                setSelected={setSelected}
                selected={selected}
              />
              <DownPaymentFilter
                RangeSliderFilter={RangeSliderFilter}
                translationPath={translationPath}
                parentTranslationPath={parentTranslationPath}
                setSelected={setSelected}
                selected={selected}
              />
              <ServiceChargeFilter
                RangeSliderFilter={RangeSliderFilter}
                translationPath={translationPath}
                parentTranslationPath={parentTranslationPath}
                setSelected={setSelected}
                selected={selected}
              />
            </div>
          </div>

          <div className='dialog-footer'>
            <Button onClick={isClose} className='cancel-button'>
              Cancel
            </Button>
            <Button variant='contained' className='show-button' disabled>
              {`Show ${detailsUnitsList.totalCount}`}
            </Button>
          </div>
        </div>
      }
    />
  );
};
